import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import CtaLink from '../components/framework/cta-link-mini';
import Framework from '../components/framework';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';

const PrivacyPolicy = ({ location }) => {
	const { language } = useI18next();
	const { t } = useTranslation('privacy_policy');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>
			<div className="container mt-3 pt-lg-5">
				<h1 className="h3">{t('t1')}</h1>
				<p>{t('t2')}</p>
				<p>
					<CtaLink
						label={t('privacy_policy:t3')}
						linkUrl={`/assets/pdf/privacy-policy-${language}.pdf`}
					/>
				</p>
			</div>
		</Framework>
	);
};

PrivacyPolicy.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(PrivacyPolicy);

export const query = graphql`
	query {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
